'use client';

import { createTheme, CustomFlowbiteTheme } from 'flowbite-react';
import {
    Pagination as FPagination,
    type PaginationProps,
} from 'flowbite-react/components/Pagination';
import { forwardRef } from 'react';

//Custom Pagination Theme
export const paginationTheme: CustomFlowbiteTheme['pagination'] = createTheme({
    base: '',
    layout: {
        table: {
            base: 'text-sm text-neutral-500 dark:text-neutral-400',
            span: 'font-semibold text-neutral-900 dark:text-white',
        },
    },
    pages: {
        base: 'xs:mt-0 mt-2 inline-flex items-center -space-x-px',
        showIcon: 'inline-flex',
        previous: {
            base: 'ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium  text-neutral-600 enabled:hover:bg-gray-100 enabled:hover:text-neutral-700 dark:border-gray-700 dark:bg-gray-800 dark:text-neutral-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
            icon: 'size-5',
        },
        next: {
            base: 'rounded-r-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium  text-neutral-600 enabled:hover:bg-gray-100 enabled:hover:text-neutral-700 dark:border-gray-700 dark:bg-gray-800 dark:text-neutral-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
            icon: 'size-5',
        },
        selector: {
            base: 'w-12 border border-gray-300 bg-white py-2 text-sm  text-neutral-600 enabled:hover:bg-gray-100 enabled:hover:text-neutral-700 dark:border-gray-700 dark:bg-gray-800 dark:text-neutral-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
            active: 'bg-primary-50 text-primary-600 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white',
            disabled: 'cursor-not-allowed opacity-50',
        },
    },
});

// Main pagination component
export const Pagination = forwardRef<HTMLDivElement, PaginationProps>((props, ref) => {
    return <FPagination ref={ref} {...props} theme={paginationTheme} />;
});

Pagination.displayName = 'Pagination';

export default Pagination;
